window.addEventListener("DOMContentLoaded", (event) => {
  $('.js-table-line-link').each(function(index) {
    var tds = $(this).parents('tr.cursor-link').find('td:not(.actions)')
    var link = $(this).data('url');

    $(tds).on('click', function(e) {
      window.location = link;
    });
  });
});
